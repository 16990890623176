import { FC } from "react";

import { VStack } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";

import { TranslationDomain } from "@utils/types";

import DiscountLabel from "../Labels/DiscountLabel";

interface IProductDiscountProps {
  isVisible?: boolean;
  value: number;
  dataTestId: string;
}

const ProductDiscount: FC<IProductDiscountProps> = ({ value, isVisible, dataTestId }) => {
  const { t } = useTranslation(TranslationDomain.PRODUCT);

  if (!isVisible) {
    return null;
  }

  return (
    <VStack data-test-id={`productDiscountLabel_${dataTestId}`} position="absolute" top="16px" right="0">
      <DiscountLabel text={t("product-discount", { value })} />
    </VStack>
  );
};

export default ProductDiscount;
