import { FC } from "react";

import { VStack } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";

import { IProduct } from "@features/types";
import { isEmpty } from "@utils/utils";

import InfoLabel from "../Labels/InfoLabel";

type TProductLabelsProps = Pick<IProduct, "productLabels">;

const ReferralProductLabels: FC<TProductLabelsProps> = ({ productLabels }) => {
  const { t } = useTranslation();

  if (isEmpty(productLabels)) {
    return null;
  }

  const customLabel = (label: string): string => {
    return label === "VAG only" ? t("product:for-vw") : label;
  };

  return (
    <VStack position="absolute" top="16px" left="16px" align="start" marginTop="0 !important">
      {productLabels.map((label) => (
        <InfoLabel
          key={label.code}
          text={customLabel(label.name)}
          bgColor="gray.150"
          color="blackAlpha.700"
          fontWeight="500"
          paddingX="8px"
          paddingY="2px"
        />
      ))}
    </VStack>
  );
};

export default ReferralProductLabels;
