import * as React from "react";
import { FC } from "react";

import { VStack, Text } from "@chakra-ui/react";
import Link from "next/link";

import { Heading } from "@components/Heading/Heading";
import Image from "@components/Image";
import { ProductDiscount, ProductLabels } from "@components/product/ProductLabels";
import ProductPriceTag from "@components/product/ProductPriceTag";
import { useAppStaticPath } from "@context/AppStaticPathContext";
import { IProduct } from "@features/types";

interface IProductBlockProps {
  newTab: boolean;
  product: IProduct;
  description: React.ReactNode;
}

const ProductBlock: FC<IProductBlockProps> = ({ newTab, product, description }) => {
  const imageSrc = product?.images?.[0]?.path;
  const { getProductPath } = useAppStaticPath();
  const hasDiscount = product.discountPercentage > 0;

  return product ? (
    <Link href={getProductPath(product.slug)} passHref target={newTab ? "_blank" : "_self"}>
      <VStack backgroundColor="gray.50" spacing={0} borderRadius="10px" as="span" position="relative">
        <VStack padding={8} spacing={6}>
          <Image src={imageSrc} width={280} height={280} objectFit="contain" alt={product.name} priority />
          <VStack spacing={2}>
            <Heading as="h5" margin={0}>
              {product.name}
            </Heading>
            <Text fontSize="textSize.labels" textAlign="center">
              {description}
            </Text>
          </VStack>
          <ProductPriceTag product={product} />
        </VStack>
        <ProductDiscount value={product.discountPercentage} isVisible={hasDiscount} dataTestId={product.name} />
        <ProductLabels productLabels={product.productLabels} />
      </VStack>
    </Link>
  ) : null;
};

export default ProductBlock;
