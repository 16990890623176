import { FC } from "react";

import { Box } from "@chakra-ui/react";

type TDiscountLabelProps = {
  text: string;
  bgColor?: string;
  color?: string;
  fontWeight?: string;
  paddingX?: string;
  paddingY?: string;
};

const DiscountLabel: FC<TDiscountLabelProps> = ({ text, bgColor, color, fontWeight, paddingX, paddingY }) => {
  return (
    <Box
      bg={bgColor || "black"}
      color={color || "white"}
      fontWeight={fontWeight || "bold"}
      borderRadius="4px"
      fontSize="textSize.labels"
      py={paddingY || "1"}
      px={paddingX || "3"}
    >
      {text}
    </Box>
  );
};

export default DiscountLabel;
