import { FC } from "react";

import { VStack } from "@chakra-ui/react";

import { IProduct } from "@features/types";
import { isEmpty } from "@utils/utils";

import InfoLabel from "../Labels/InfoLabel";

type TProductLabelsProps = Pick<IProduct, "productLabels">;

const ProductLabels: FC<TProductLabelsProps> = ({ productLabels }) => {
  if (isEmpty(productLabels)) {
    return null;
  }

  return (
    <VStack data-test-id="productLabel" position="absolute" top="16px" left="16px" align="start">
      {productLabels.map((label) => (
        <InfoLabel key={label.code} text={label.name} />
      ))}
    </VStack>
  );
};

export default ProductLabels;
